import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { Calendar } from 'primereact/calendar';
import QuestionWrapper from '../global/QuestionWrapper';
import useNavigateNext from '../hooks/useNavigateNext';

const minDate = new Date();

function TravelDatesQuestion({ next, ...rest }) {
	const formik = useFormikContext();
	const [field, meta, form] = useField('travelDates');
	const { onBlur, ...fieldProps } = field;
	const showError = meta.touched && meta.error;
	const navigateNext = useNavigateNext({ url: next, validate: form.setTouched, field: field.name });

	return (
		<QuestionWrapper field={field.name} validate={navigateNext} {...rest}>
			<div className="page-question">
				<span>I want to travel from</span>
				<div className="field-wrapper">
					<Calendar
						className={showError ? 'p-invalid' : ''}
						dateFormat="mm/dd/yy"
						id={field.name}
						minDate={minDate}
						numberOfMonths={2}
						selectOtherMonths={true}
						readOnlyInput={formik.values.booking[0] !== 'One-Way Flight'}
						selectionMode={formik.values.booking[0] === 'One-Way Flight' ? 'single' : 'range'}
						{...fieldProps}
					/>
					{showError && <small className="p-invalid">{meta.error}</small>}
				</div>
			</div>
		</QuestionWrapper>
	);
}

TravelDatesQuestion.displayName = 'TravelDatesQuestion';

TravelDatesQuestion.propTypes = {
	next: PropTypes.string,
	submit: PropTypes.bool,
};

TravelDatesQuestion.defaultProps = {
	next: '',
	submit: false,
};

export default TravelDatesQuestion;
