import { useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { AutoComplete } from 'primereact/autocomplete';
import localRequest from '../../utils/ajax';
import QuestionWrapper from '../global/QuestionWrapper';
import { destinationItemDisplay } from './utils';
import useNavigateNext from '../hooks/useNavigateNext';

function DestinationPlaceQuestion({ next, ...rest }) {
	const [field, meta, form] = useField('destination');
	const { onBlur, onChange, ...fieldProps } = field;
	const [filteredAirports, setFilteredAirports] = useState([]);
	const navigateNext = useNavigateNext({ url: next, validate: form.setTouched, field: field.name });

	const showError = meta.touched && meta.error && meta?.error?.position?.lat;

	const searchCountry = e => {
		setTimeout(async () => {
			if (!e.query.trim().length || e.query.trim().length <= 2) {
				const newAirportOptions = [];
				setFilteredAirports(newAirportOptions);
			} else {
				try {
					const airports = await localRequest.post('/api/v1/locations/find', { query: e.query.trim() });
					setFilteredAirports(airports.data);
				} catch (error) {
					console.error(error);
				}
			}
		}, 250);
	};

	const updateValue = value => {
		const newValue = typeof value === 'object' ? value : { address: { freeformAddress: value } };
		form.setValue(newValue, false);
	};

	const handleChange = ({ originalEvent, value }) => {
		// eslint-disable-next-line no-underscore-dangle
		if (originalEvent._reactName === 'onChange') {
			updateValue(value);
		}
	};

	const handleSelect = ({ value }) => {
		updateValue(value);
		setTimeout(navigateNext, 100);
	};

	const handleKeyPress = e => {
		if (e.charCode === 13) {
			e.preventDefault();
			navigateNext();
		}
	};

	return (
		<QuestionWrapper field={field.name} validate={navigateNext} {...rest}>
			<div className="page-question">
				<span>I feel like going to</span>
				<div className="field-wrapper">
					<span className="p-float-label">
						<AutoComplete
							suggestions={filteredAirports}
							completeMethod={searchCountry}
							field="address.freeformAddress"
							panelStyle={{ maxHeight: '300px' }}
							itemTemplate={destinationItemDisplay}
							className={showError ? 'p-invalid' : ''}
							id={field.name}
							onChange={handleChange}
							onSelect={handleSelect}
							onKeyPress={handleKeyPress}
							{...fieldProps}
						/>
						<label htmlFor={field.name}>type a destination</label>
					</span>
					{showError && <small className="p-invalid">{meta?.error?.position?.lat}</small>}
				</div>
			</div>
		</QuestionWrapper>
	);
}

DestinationPlaceQuestion.displayName = 'DestinationPlaceQuestion';

DestinationPlaceQuestion.propTypes = {
	next: PropTypes.string,
	submit: PropTypes.bool,
};

DestinationPlaceQuestion.defaultProps = {
	next: '',
	submit: false,
};

export default DestinationPlaceQuestion;
