import DestinationPage from './templates';
import { urlkeys } from '../utils/constants';
import BookByDestinationForm from '../forms/TravelForms';

const { bookByDest } = urlkeys;

const BookByDestinationPage = () => (
	<DestinationPage className="book-by-destination-page" urlkey={bookByDest}>
		<div className="page-wrapper">
			<div className="page-content">
				<div className="page-heading">Book By Destination</div>
				<BookByDestinationForm />
			</div>
		</div>
	</DestinationPage>
);

BookByDestinationPage.displayName = 'BookByDestinationPage';

export default BookByDestinationPage;
