import DestinationPage from './templates';
import { urlkeys } from '../utils/constants';

const { comingSoon } = urlkeys;

const ComingSoonPage = () => (
	<DestinationPage className="coming-soon-page" urlkey={comingSoon}>
		<div className="page-wrapper">
			<div className="page-content">
				<div className="page-heading">Page Coming Soon!</div>
			</div>
		</div>
	</DestinationPage>
);

ComingSoonPage.displayName = 'BookByBudgetPage';

export default ComingSoonPage;
