import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import './DestinationPage.scss';
import { Header } from '../../../components/global';
import { LocationContext, SET_CURRENT_LOCATION } from '../../../context/LocationContext';
import DestinationDisplay from '../../../components/global/DestinationDisplay';

const pageVariants = {
	initial: { opacity: 0 },
	in: { opacity: 1 },
	out: { opacity: 0 },
};

const pageTransition = {
	type: 'easeIn',
	duration: '0.8',
};

const DestinationPage = ({ children, className, urlkey }) => {
	const { state, dispatch } = useContext(LocationContext);
	const { locations } = state;

	useEffect(() => {
		if (locations?.length > 0) {
			dispatch({ type: SET_CURRENT_LOCATION, payload: urlkey });
		}
	}, [locations, dispatch, urlkey]);

	return (
		<motion.div
			initial="initial"
			animate="in"
			exit="out"
			variants={pageVariants}
			transition={pageTransition}
			className={`page ${className}`}
		>
			<Header />
			{children}
			<DestinationDisplay />
		</motion.div>
	);
};

DestinationPage.displayName = 'DestinationPage';

DestinationPage.propTypes = {
	className: PropTypes.string,
	children: PropTypes.element.isRequired,
	urlkey: PropTypes.string.isRequired,
};

DestinationPage.defaultProps = { className: '' };

export default DestinationPage;
