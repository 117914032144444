import PropTypes from 'prop-types';
import ButtonArrowDisplay from './ButtonArrowDisplay';

const NextArrowButton = ({ handleClick, ...rest }) => (
	<ButtonArrowDisplay className="next-button" onClick={handleClick} label="next" {...rest} />
);

NextArrowButton.displayName = 'NextArrowButton';

NextArrowButton.propTypes = {
	validate: PropTypes.func,
	noValidate: PropTypes.bool,
};

export default NextArrowButton;
