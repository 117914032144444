import { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { AutoComplete } from 'primereact/autocomplete';
import QuestionWrapper from '../global/QuestionWrapper';
import useNavigateNext from '../hooks/useNavigateNext';
import localRequest from '../../utils/ajax';
import { LocationContext, SET_USERS_LOCATION } from '../../context/LocationContext';
import { airportItemDisplay } from './utils';

function DepartureAirportQuestion({ next, ...rest }) {
	const { state, dispatch } = useContext(LocationContext);
	const { userLocation } = state;
	const [field, meta, form] = useField('deptAirport');
	const { onBlur, ...fieldProps } = field;
	const [nearbyAirports, setNearbyAirports] = useState([]);
	const [filteredAirports, setFilteredAirports] = useState([]);
	const navigateNext = useNavigateNext({ url: next, validate: form.setTouched, field: field.name });

	const showError = meta.touched && meta.error && meta.error.iata;

	// Get list of airports
	useEffect(() => {
		if (userLocation.latitude) {
			(async () => {
				try {
					const res = await localRequest.post('/api/v1/airports/nearby', {
						latitude: userLocation.latitude,
						longitude: userLocation.longitude,
					});
					setNearbyAirports(res.data);
				} catch (err) {
					console.log(err);
				}
			})();
		}
	}, [userLocation]);

	// set ip location
	const setIpLocation = useCallback(
		pos => {
			try {
				dispatch({
					type: SET_USERS_LOCATION,
					payload: { longitude: pos.longitude, latitude: pos.latitude },
				});
			} catch (error) {
				console.error(error);
			}
		},
		[dispatch]
	);

	// Get customers location (browser api and fallback on 3rd party api)
	useEffect(() => {
		if (!state.userLocation.latitude) {
			console.log('getting users location!');
			navigator.geolocation.getCurrentPosition(
				pos => {
					const { longitude, latitude } = pos.coords;
					setIpLocation({ longitude, latitude });
				},
				async () => {
					try {
						const iplocation = await localRequest.get('/api/v1/user/locate');
						setIpLocation({ longitude: iplocation.longitude, latitude: iplocation.latitude });
					} catch (error) {
						console.error(error);
					}
				}
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const searchCountry = e => {
		setTimeout(async () => {
			if (!e.query.trim().length || e.query.trim().length <= 2) {
				const newAirportOptions = [...nearbyAirports.results];
				setFilteredAirports(newAirportOptions);
			} else {
				try {
					const airports = await localRequest.post('/api/v1/airports/find', { query: e.query.trim() });
					console.log(airports);
					setFilteredAirports(airports.data.results);
				} catch (error) {
					console.error(error);
				}
			}
		}, 250);
	};

	const handleSelect = async e => {
		const { value } = e;
		const { lat, lon } = value.position;
		const params = { latitude: lat, longitude: lon };

		try {
			const response = await localRequest.post('/api/v1/airports/get-code', params);
			const airport = response.data;
			form.setValue({ ...value, name: value.poi.name, iata: airport.iata });
			navigateNext();
		} catch (error) {
			console.error(error);
		}
	};

	const handleKeyPress = e => {
		if (e.charCode === 13) {
			e.preventDefault();
			navigateNext();
		}
	};

	return (
		<QuestionWrapper field={field.name} validate={navigateNext} {...rest}>
			<div className="page-question">
				<span>I want to leave from</span>
				<div className="field-wrapper">
					<span className="p-float-label">
						<AutoComplete
							suggestions={filteredAirports}
							completeMethod={searchCountry}
							onSelect={handleSelect}
							onKeyPress={handleKeyPress}
							field="poi.name"
							dropdown
							panelStyle={{ maxHeight: '300px' }}
							itemTemplate={airportItemDisplay}
							className={showError ? 'p-invalid' : ''}
							id={field.name}
							{...fieldProps}
						/>
						<label htmlFor={field.name}>choose an airport</label>
					</span>
					{showError && <small className="p-invalid">{meta?.error?.iata}</small>}
				</div>
			</div>
		</QuestionWrapper>
	);
}

DepartureAirportQuestion.displayName = 'DepartureAirportQuestion';

DepartureAirportQuestion.propTypes = {
	next: PropTypes.string,
	submit: PropTypes.bool,
};

DepartureAirportQuestion.defaultProps = {
	next: '',
	submit: false,
};

export default DepartureAirportQuestion;
