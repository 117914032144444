export const API_URL = process.env.REACT_APP_API_URL;

export const AMADEUS_API = process.env.REACT_APP_AMADEUS_API_URL;

export const urlkeys = {
	bookByBudget: '/book-by-budget',
	bookByDest: '/book-by-destination',
	bookByExperience: '/book-by-experience',
	login: '/login',
	savedTrips: '/saved-trips',
	comingSoon: '/coming-soon',
};

export const questionPaths = {
	bookByDestination: 'book-by-destination',
	bookByBudget: 'book-by-budget',
	bookByExperience: 'book-by-experience',
};
