import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import ButtonArrowDisplay from './ButtonArrowDisplay';

const PrevArrowButton = ({ url, handleClick: onClick = null, ...rest }) => {
	const history = useHistory();
	const handleClick = () => (onClick ? onClick() : history.push(url));

	return <ButtonArrowDisplay className="prev-button" onClick={handleClick} label="prev" {...rest} />;
};

PrevArrowButton.displayName = 'PrevArrowButton';

PrevArrowButton.propTypes = {
	url: PropTypes.string.isRequired,
};

export default PrevArrowButton;
