import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import localRequest from '../../utils/ajax';
import QuestionWrapper from '../global/QuestionWrapper';
import useNavigateNext from '../hooks/useNavigateNext';
import { airportItemDisplay } from './utils';

function DestinationAirlineQuestion({ next, ...rest }) {
	const formik = useFormikContext();
	const { destination } = formik.values;
	const { lat = '', lon = '' } = destination.position || {};

	const [displayField, , displayFormHelpers] = useField('destAirportDisplay');
	const [field, meta, form] = useField('destAirport');
	const [airports, setAirports] = useState([]);
	const navigateNext = useNavigateNext({ url: next, validate: form.setTouched, field: field.name });

	const firstError = meta.error && Object.keys(meta.error)[0];
	const showError = meta.touched && meta.error;

	useEffect(() => {
		(async () => {
			try {
				const airportOptions = await localRequest.post('/api/v1/airports/nearby', { latitude: lat, longitude: lon });
				console.log(airportOptions);
				setAirports(airportOptions.data.results);
			} catch (error) {
				console.error(error);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = async e => {
		const { value } = e;
		displayFormHelpers.setValue(value);

		const { lat: latitude, lon: longitude } = value.position;

		try {
			const response = await localRequest.post('/api/v1/airports/get-code', { latitude, longitude });
			const airport = response.data;
			console.log({ airport, value });
			formik.setFieldValue('destAirport', { ...value, name: value.poi.name, iata: airport.iata });
			navigateNext();
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<QuestionWrapper field={displayField.name} validate={navigateNext} {...rest}>
			<div className="page-question">
				<span>I feel like going to</span>
				<div className="field-wrapper">
					<Dropdown
						options={airports}
						optionLabel="poi.name"
						placeholder="Select an Airport"
						panelStyle={{ maxHeight: '300px' }}
						itemTemplate={airportItemDisplay}
						className={showError ? 'p-invalid' : ''}
						id={displayField.name}
						name={displayField.name}
						value={displayField.value}
						onChange={handleChange}
					/>
					{showError && <small className="p-invalid">{meta.error[firstError]}</small>}
				</div>
			</div>
		</QuestionWrapper>
	);
}

DestinationAirlineQuestion.displayName = 'DestinationAirlineQuestion';

DestinationAirlineQuestion.propTypes = {
	next: PropTypes.string,
	submit: PropTypes.bool,
};

DestinationAirlineQuestion.defaultProps = {
	next: '',
	submit: false,
};

export default DestinationAirlineQuestion;
