import { useHistory } from 'react-router-dom';
import PrevArrowButton from '../../forms/global/PrevArrowButton';
import FlightCard from './FlightCard';

const DisplayResults = ({ results, hasResults, path }) => {
	const history = useHistory();
	const goBack = () => (path.length ? history.goBack() : history.push('/'));

	return (
		<div className="results-scroll">
			{hasResults ? (
				results.map((result, i) => <FlightCard key={i} result={result} />)
			) : (
				<div className="no-results">
					<p>We're sorry we could not find any flights for your request.</p>
					<PrevArrowButton url="" handleClick={goBack} />
				</div>
			)}
		</div>
	);
};

export default DisplayResults;
