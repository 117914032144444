import dayjs from 'dayjs';
import { isDate } from 'lodash';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ResultsContext } from '../../context/ResultsContext';
import { urlkeys } from '../../utils/constants';
import './AnswerSummary.scss';

const { bookByDest } = urlkeys;

const QuestionAnswer = ({ question, answer, url }) => (
	<div className="question-answer">
		<div className="question">
			{question}
			<span className="edit">
				(<Link to={url}>Edit</Link>)
			</span>
		</div>
		{typeof answer === 'string' ? (
			<div className="answer">{answer}</div>
		) : (
			answer.map(a => (
				<div className="answer" key={a}>
					{a}
				</div>
			))
		)}
	</div>
);

const AnswerSummary = () => {
	const { state } = useContext(ResultsContext);
	const { answers } = state.question;
	const departDate = isDate(answers.travelDates) ? answers.travelDates : answers.travelDates[0].toString();
	const returnDate = isDate(answers.travelDates) ? '' : answers.travelDates[1].toString();

	return (
		<div className="questions-answers">
			<div className="title">
				<span className="latlon">
					{answers.destination.position.lat}, {answers.destination.position.lon}
				</span>
				<span className="place">{answers.destination.address.municipality}</span>
				<span className="country">{answers.destination.address.country}</span>
			</div>

			<QuestionAnswer
				question="Destination"
				answer={`${answers.destination.address.freeformAddress}, ${answers.destination.address.countryCodeISO3}`}
				url={`${bookByDest}/visiting-location`}
			/>
			<QuestionAnswer question="I Need" answer={answers.booking.join(', ')} url={`${bookByDest}/booking`} />
			<QuestionAnswer
				question="Leaving From"
				answer={`${answers.deptAirport.name} (${answers.deptAirport.iata})`}
				url={`${bookByDest}/departure-airport`}
			/>
			<QuestionAnswer
				question="Arriving At"
				answer={`${answers.destAirport.name} (${answers.destAirport.iata})`}
				url={`${bookByDest}/visiting-airport`}
			/>
			<QuestionAnswer
				question="Leaving On"
				answer={dayjs(departDate).format('MM/DD/YYYY')}
				url={`${bookByDest}/travel-dates`}
			/>
			{returnDate.length > 0 && (
				<QuestionAnswer
					question="Returning On"
					answer={dayjs(returnDate).format('MM/DD/YYYY')}
					url={`${bookByDest}/travel-dates`}
				/>
			)}
			<QuestionAnswer
				question="Travellers"
				answer={[
					`${answers.passengers.adults} Adults`,
					`${answers.passengers.children} Children`,
					`${answers.passengers.infants} Infants`,
				]}
				url={`${bookByDest}/passengers`}
			/>
		</div>
	);
};

export default AnswerSummary;
