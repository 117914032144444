import { Link } from 'react-router-dom';
import './NavBar.scss';

const NavBar = () => (
	<nav className="navbar">
		{
			// need to include the language switcher
		}
		<Link className="navbar-link" to="/login">
			<span>sign in</span>
		</Link>
		<Link className="navbar-link" to="/my-trips">
			<span>saved trips</span>
		</Link>
		<Link className="navbar-link" to="/contact-us">
			<span>contact us</span>
		</Link>
	</nav>
);

NavBar.displayName = 'NavBar';

export default NavBar;
