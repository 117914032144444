import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './FlightSuccesPage.scss';

import DestinationPage from '../templates';
import { ResultsContext } from '../../context/ResultsContext';
import Filters from '../../components/success-page/filters/Filters';
import DisplayResults from '../../components/success-page/DisplayResults';
import AnswerSummary from '../../components/success-page/AnswerSummary';

const FlightSuccessPage = () => {
	const location = useLocation();
	const { state } = useContext(ResultsContext);
	const { results, question } = state;
	const { flights } = results;
	const [filteredResults, setFilteredResults] = useState([]);
	const updateFilteredResults = useCallback(newResults => setFilteredResults(newResults), []);
	const hasResults = flights?.results?.length > 0;

	// TODO: remove this later
	console.log({ requestUrl: flights.requestUrl });

	useEffect(() => {
		console.log({ res: flights.results });
		if (flights.results && flights.results.length) {
			setFilteredResults(flights.results);
		}
	}, [flights.results]);

	return (
		<DestinationPage className="flight-success-page" urlkey={location.pathname}>
			<div className="page-wrapper">
				<div className="page-content">
					<div className="page-heading">
						{hasResults ? `${question.heading} Results (${filteredResults.length})` : 'No Results Found'}
					</div>
					<div className="results flights">
						{flights.results && (
							<div className="left-panel">
								<AnswerSummary />
								{hasResults && <Filters updateResults={updateFilteredResults} />}
							</div>
						)}
						<DisplayResults results={filteredResults} hasResults={hasResults} path={question.path} />
					</div>
				</div>
			</div>
		</DestinationPage>
	);
};

FlightSuccessPage.displayName = 'FlightSuccessPage';

export default FlightSuccessPage;
