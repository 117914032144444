import { useContext, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { LocationContext } from '../../context/LocationContext';
import { API_URL } from '../../utils/constants';

const defaultLocationState = {
	image: '',
	latitude: '',
	longitude: '',
	location: '',
	country: '',
};

const pageVariants = {
	initial: { opacity: 0 },
	in: { opacity: 1 },
	out: { opacity: 0 },
};

const pageTransition = {
	type: 'easeIn',
	duration: '0.8',
};

const DestinationDisplay = () => {
	const [page, setPage] = useState('');
	const [location, setLocation] = useState(defaultLocationState);
	const { state } = useContext(LocationContext);
	const { currentPage, viewLocations } = state;

	useEffect(() => {
		if (currentPage && page !== currentPage) {
			setPage(currentPage);
			setLocation(viewLocations[currentPage]);
		}
	}, [currentPage, page, viewLocations]);

	const { image, latitude, longitude, location: destinationLocation, country } = location;
	const style = image ? { backgroundImage: `url(${API_URL}${image})` } : {};
	const coords = latitude && longitude ? `${latitude} ${longitude}` : null;

	return (
		<AnimatePresence>
			<motion.div
				key={currentPage}
				initial="initial"
				animate="in"
				exit="out"
				variants={pageVariants}
				transition={pageTransition}
				className="page-destination-info"
				style={style}
			>
				{(coords || destinationLocation || country) && (
					<div className="location-info">
						{coords && <span className="coords">{coords}</span>}
						{destinationLocation && <span className="place">{destinationLocation}</span>}
						{country && <span className="country">{country}</span>}
					</div>
				)}
			</motion.div>
		</AnimatePresence>
	);
};

DestinationDisplay.displayName = 'DestinationDisplay';

export default DestinationDisplay;
