import axios from 'axios';

import { API_URL } from './constants';

const localRequest = axios.create({
	baseURL: API_URL,
	timeout: 5000,
});

export default localRequest;
