import { useContext, useState } from 'react';
import dayjs from 'dayjs';
import { motion, AnimatePresence } from 'framer-motion';
import './FlightCard.scss';
import { AMADEUS_API } from '../../utils/constants';
import { LocationContext } from '../../context/LocationContext';

const Flight = ({ trip, title }) => {
	const { state: appState } = useContext(LocationContext);
	const { airports } = appState;

	return (
		<>
			{trip && trip.flights && (
				<div className="flights">
					<div className="flights-title">{title}</div>
					{trip.flights.map((flight, i) => {
						const deptAirline = airports.find(airport => airport.iata === flight.origin.airport);
						const destAirline = airports.find(airport => airport.iata === flight.destination.airport);

						return (
							<div className="flight" key={`inbound-${i}`}>
								<div className="airports">
									<div className="airport">
										<div className="flight-title departs">Departs from:</div>
										<span className="airport-name">{deptAirline.name}</span>
										<span className="airport-code">({flight.origin.airport})</span>
										<div className="departure">
											<span>{dayjs(flight.departs_at).format('MM/DD/YYYY hh:mm A')}</span>
										</div>
									</div>
									<span className="separator">
										<span className="pi pi-arrow-right" />
									</span>
									<div className="airport">
										<div className="flight-title arrives">Arrives at:</div>
										<span className="airport-name">{destAirline.name}</span>
										<span className="airport-code">({flight.destination.airport})</span>
										<div className="departure">
											<span>{dayjs(flight.arrives_at).format('MM/DD/YYYY hh:mm A')}</span>
											<span className="duration">(Duration: {flight.duration})</span>
										</div>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			)}
		</>
	);
};

const FlightCard = ({ result }) => {
	const { state: appState } = useContext(LocationContext);
	const [showFlights, setShowFlights] = useState(false);
	const toggleFlights = () => setShowFlights(state => !state);

	const flight = result.outbound.flights[0];
	const airlineCode = flight.marketing_airline;

	let merchant;
	if (flight.operating_airline_type !== 'IATA') {
		merchant = { name: flight.operating_airline };
	} else {
		merchant = appState.merchants.find(m => m.code === airlineCode);
	}

	return (
		<div className="card flights-card">
			<div className="flight-info">
				<div className="flight-info-left">
					<div className="title">
						<div className="airline">
							{merchant && merchant.logo_ref && (
								<img src={`${AMADEUS_API}${merchant.logo_ref.small}`} alt={`${merchant.name} airline`} />
							)}
							<span className="airline">
								{merchant && merchant.name} ({airlineCode})
							</span>
						</div>
					</div>
					<div className="travel-class">{result.travel_class.toLowerCase().split('_').join(' ')}</div>
					<div className="duration">Total Travel Time: {result.outbound.duration}</div>
				</div>
				<div className="flight-info-right">
					<div className="total">
						<span>${result.fare.total_price}</span>
					</div>
					{result.deep_link && (
						<a href={result.deep_link} rel="noreferrer" target="_blank" className="button book-now">
							<span>Book It</span>
						</a>
					)}
				</div>
			</div>
			<div className="flights">
				<div className="flights-heading">
					<span>Flights</span>
					<button type="button" onClick={toggleFlights}>
						<span>{showFlights ? '-' : '+'}</span>
					</button>
				</div>
				<AnimatePresence>
					{showFlights && (
						<motion.div
							className="flights-wrapper"
							style={{ overflow: 'hidden' }}
							initial={{ height: 0 }}
							animate={{ height: 'auto' }}
							exit={{ height: 0 }}
							transition={{ duration: 0.5 }}
						>
							<Flight trip={result.outbound} title="Outbound" />
							<Flight trip={result.inbound} title="Inbound" />
						</motion.div>
					)}
				</AnimatePresence>
			</div>
		</div>
	);
};

FlightCard.displayName = 'FlightCard';

export default FlightCard;
