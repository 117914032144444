import PropTypes from 'prop-types';
import './BookingForQuestion.scss';
import { useField } from 'formik';
import { MultiSelect } from 'primereact/multiselect';
import QuestionWrapper from '../global/QuestionWrapper';
import useNavigateNext from '../hooks/useNavigateNext';

const bookingOptions = [
	{ label: 'One-Way Flight', value: 'One-Way Flight' },
	{ label: 'Round Trip', value: 'Round Trip' },
	{ label: 'Hotel', value: 'Hotel' },
	{ label: 'Car', value: 'Car' },
];

// const constrainedValues = ['One-Way Flight', 'Round Trip'];

function BookingForQuestion({ next, ...rest }) {
	const [field, meta, form] = useField('booking');
	const { onBlur, onChange, ...fieldProps } = field;
	const showError = meta.touched && meta.error;
	const navigateNext = useNavigateNext({ url: next, validate: form.setTouched, field: field.name });

	const handleChange = e => {
		const { value } = e;
		const newValue = value.filter(v => !field.value.includes(v))[0];

		if (newValue === 'Round Trip' && field.value.indexOf('One-Way Flight') > -1) {
			const index = field.value.indexOf('One-Way Flight');
			value.splice(index, 1);
		} else if (newValue === 'One-Way Flight' && field.value.indexOf('Round Trip') > -1) {
			const index = field.value.indexOf('Round Trip');
			value.splice(index, 1);
		}

		form.setValue(value);
	};

	return (
		<QuestionWrapper field={field.name} validate={navigateNext} {...rest}>
			<div className="page-question booking-for">
				<span>I want to book a</span>
				<div className="field-wrapper">
					<MultiSelect
						options={bookingOptions}
						optionLabel="label"
						className={`multiselect ${showError ? 'p-invalid' : ''}`}
						display="chip"
						id={field.name}
						filter={false}
						scrollHeight="auto"
						onChange={handleChange}
						{...fieldProps}
					/>
					{showError && <small className="p-invalid">{meta.error}</small>}
				</div>
			</div>
		</QuestionWrapper>
	);
}

BookingForQuestion.displayName = 'BookingForQuestion';

BookingForQuestion.propTypes = {
	next: PropTypes.string,
	submit: PropTypes.bool,
};

BookingForQuestion.defaultProps = {
	next: '',
	submit: false,
};

export default BookingForQuestion;
