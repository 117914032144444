import './HomePage.scss';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';
import DestinationPage from '../templates';
import { RESET_QUESTION_ANSWERS, ResultsContext } from '../../context/ResultsContext';
import { urlkeys } from '../../utils/constants';

const { bookByBudget, bookByDest, bookByExperience } = urlkeys;

const HomePage = () => {
	const history = useHistory();
	const { dispatch } = useContext(ResultsContext);

	const handleClick = url => {
		dispatch({ type: RESET_QUESTION_ANSWERS });
		history.push(url);
	};

	return (
		<DestinationPage className="homepage" urlkey="homepage">
			<div className="page-wrapper">
				<div>
					<p className="page-heading">It's a new day for vacation planning...</p>
					<p className="page-message">
						With TripStrategist, you can easily search and book your vacation by budget, destination, or experience.
						We’ll curate a picture-perfect vacation for you.
						<br />
						This is vacation planning, <strong>redefined</strong>!
					</p>
					<div className="page-cta">
						<p className="page-cta-heading">
							Click a <strong>BOOK BY</strong> option to get started.
						</p>
						<div className="page-cta-options">
							<Button
								onClick={() => handleClick(bookByBudget)}
								className="p-button-text p-button-large p-button-plain"
								type="button"
								label="Book by Budget"
							/>
							<Button
								className="p-button-text p-button-large p-button-plain"
								type="button"
								label="Book by Destination"
								onClick={() => handleClick(bookByDest)}
							/>
							<Button
								className="p-button-text p-button-large p-button-plain"
								type="button"
								label="Book by Experience"
								onClick={() => handleClick(bookByExperience)}
							/>
						</div>
					</div>
				</div>
			</div>
		</DestinationPage>
	);
};

HomePage.displayName = 'HomePage';

export default HomePage;
