import { useEffect, useContext, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Switch, Route, useLocation } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import PrimeReact from 'primereact/utils';
import './App.scss';
import './Animations.scss';
import localRequest from './utils/ajax';
import { LocationContext, SET_INITAL_APP_DATA } from './context/LocationContext';
import { HomePage, BookByDestinationPage, ComingSoonPage, SuccessPage } from './pages';

const App = () => {
	const [isLoading, setIsLoading] = useState(true);
	const { dispatch } = useContext(LocationContext);
	const location = useLocation();
	const key = location.pathname.split('/')[1] || 'home';

	PrimeReact.ripple = true;

	// fix Google Analytics and Tagmanager for SPA navigation
	useEffect(() => {
		const pageView = {
			event: 'Pageview',
			pagePath: location.pathname,
			pageTitle: document.title,
		};

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push(pageView);
	}, [location.pathname]);

	// fetch airports, airlines, and locations from server
	useEffect(() => {
		(async () => {
			try {
				const locationsRequest = localRequest.get(`/api/v1/locations`);
				const airportsRequest = localRequest.get('/api/v1/airports');
				const merchantsRequest = localRequest.get('/api/v1/airline-merchants');

				const [res, airports, merchants] = await Promise.all([locationsRequest, airportsRequest, merchantsRequest]);

				dispatch({
					type: SET_INITAL_APP_DATA,
					payload: { locations: res.data.locations, airports: airports.data, merchants: merchants.data.merchants },
				});
				setIsLoading(false);
			} catch (err) {
				console.log(err);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (isLoading) {
		return (
			<div className="page" style={{ justifyContent: 'center', alignItems: 'center' }}>
				<ProgressSpinner />
			</div>
		);
	}

	return (
		<div className="app p-input-filled">
			<AnimatePresence>
				<Switch location={location} key={key}>
					<Route path="/" exact component={HomePage} />
					{/* <Route path="/book-by-budget" component={BookByBudgetPage} /> */}
					<Route path="/book-by-destination" component={BookByDestinationPage} />
					<Route path="/success" component={SuccessPage} />
					<Route
						path={['/login', '/book-by-budget', '/my-trips', '/contact-us', '/book-by-experience']}
						component={ComingSoonPage}
					/>
				</Switch>
			</AnimatePresence>
		</div>
	);
};

export default App;
