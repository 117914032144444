import { Switch, Route, useLocation, useRouteMatch } from 'react-router-dom';

import FlightSuccessPage from './FlightSuccessPage';

const SucessPage = () => {
	const match = useRouteMatch();
	const location = useLocation();

	return (
		<Switch location={location} key={location.pathname}>
			<Route path={`${match.url}/flights`}>
				<FlightSuccessPage />
			</Route>
		</Switch>
	);
};

export default SucessPage;
