import { Link } from 'react-router-dom';
import NavBar from './NavBar';
import './Header.scss';

const Header = () => (
	<header className="header">
		<Link className="logo" to="/">
			<div>TripStrategist.com</div>
		</Link>
		<NavBar />
	</header>
);

Header.displayName = 'Header';

export default Header;
