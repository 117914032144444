const setCurrentLocation = (state, url) => {
	const { currentPage, viewLocations, remainingLocations } = state;

	if (currentPage === url) return state;
	if (url in viewLocations) return { ...state, currentPage: url };

	const index = Math.floor(Math.random() * remainingLocations.length);
	const loc = remainingLocations[index];
	const newRemainingLocations = remainingLocations.filter(location => location !== loc);
	const newLocations = { ...viewLocations, [url]: loc };

	return { ...state, currentPage: url, viewLocations: newLocations, remainingLocations: newRemainingLocations };
};

export default setCurrentLocation;
