import { useContext, useEffect } from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import dayjs from 'dayjs';
import '../FormStyles.scss';
import { AnimatePresence } from 'framer-motion';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Switch, Redirect, Route, useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import localRequest from '../../utils/ajax';
import { questionPaths } from '../../utils/constants';
import { ResultsContext, SET_FLIGHT_RESULTS, SET_QUESTION_INFO } from '../../context/ResultsContext';

import DestinationPlaceQuestion from '../Questions/DestinationPlaceQuestion';
import DestinationAirlineQuestion from '../Questions/DestinationAirlineQuestion';
import BookingForQuestion from '../Questions/BookingForQuestion';
import PassengersNumberQuestion from '../Questions/PassengersNumberQuestion';
import TravelDatesQuestion from '../Questions/TravelDatesQuestion';
import DepartureAirportQuestion from '../Questions/DepartureAirportQuestion';

const { bookByDestination } = questionPaths;

// routes
const routes = [
	{ location: 'visiting-location', component: DestinationPlaceQuestion },
	{ location: 'visiting-airport', component: DestinationAirlineQuestion },
	{ location: 'departure-airport', component: DepartureAirportQuestion },
	{ location: 'booking', component: BookingForQuestion },
	{ location: 'travel-dates', component: TravelDatesQuestion },
	{ location: 'passengers', component: PassengersNumberQuestion },
];

// inital form values
const initialValues = {
	destination: { address: { freeformAddress: '' } },
	destAirportDisplay: {},
	destAirport: { poi: { name: '' } },
	deptAirport: { poi: { name: '' } },
	booking: [],
	passengers: {
		adults: 0,
		children: 0,
		infants: 0,
		total: 0,
	},
	travelDates: [],
};

// validation logic for form
const yString = yup.string().ensure().trim();
const validationSchema = yup.object().shape({
	destination: yup
		.object()
		.shape({
			position: yup.object().shape({
				lat: yup.number().required('Sorry, we cannot find this destination, please try again'),
				lon: yup.number().required('Sorry, we cannot find this destination, please try again'),
			}),
		})
		.typeError('Please choose an option from the drop down.'),
	booking: yup.array().of(yup.string()).min(1, 'Select a booking option').required('Select a booking option'),
	destAirport: yup
		.object()
		.shape({
			iata: yString.required('Cannot locate this airport, please try again or use a different airport.'),
			name: yup.string().ensure().trim().required('Cannot locate airport.'),
		})
		.typeError('Please choose an option from the drop down.'),
	deptAirport: yup
		.object()
		.shape({
			iata: yString.required('Cannot locate this airport, please try again or use a different airport.'),
			name: yup.string().ensure().trim().required('Cannot locate airport.'),
		})
		.typeError('Please choose an option from the drop down.'),
	passengers: yup
		.object()
		.shape({
			adults: yup.number().required(),
			children: yup.number().required(),
			infants: yup.number().required(),
			total: yup.number().min(1, 'Add passengers').required('Add passengers'),
		})
		.typeError('Please choose an option from the drop down.'),
	travelDates: yup.lazy(value => {
		switch (typeof value) {
			case 'array':
				return yup
					.array('Please select a departure and return date')
					.of(
						yup
							.date('A valid date is required.')
							.min(dayjs().format('YYYY-MM-DD'), 'Please select a departure date of today or later.')
					)
					.min(2, 'Select your travel dates.')
					.required('Please select your travel dates');
			case 'string':
			default:
				return yup
					.string('Please select a departure date')
					.ensure()
					.trim()
					.transform(val => dayjs(val).format('YYYY-MM-DD'))
					.required('Please select your travel dates');
		}
	}),
});

const BookByDestinationForm = () => {
	const { state, dispatch } = useContext(ResultsContext);
	const location = useLocation();
	const match = useRouteMatch();
	const history = useHistory();

	const handleSubmit = async values => {
		console.log('form was submitted');
		console.log(values);

		try {
			// get flights response, sort by price, and save to state
			const response = await localRequest.post('/api/v1/trip/flights', values, { timeout: null });
			response.data.results.sort((a, b) => a.fare.total_price - b.fare.total_price);
			dispatch({ type: SET_FLIGHT_RESULTS, payload: response.data });
			dispatch({
				type: SET_QUESTION_INFO,
				payload: { question: bookByDestination, heading: 'Book By Destination', answers: values },
			});

			const querystring = {
				destination: values.destination.address.freeformAddress,
				arrivalairport: values.destAirport.iata,
				deptartairport: values.deptAirport.iata,
				travelling: values.passengers.total,
				booking: values.booking.join(','),
			};

			if (typeof travelDates === 'string') {
				querystring.departdate = dayjs(values.travelDates).format('YYYY-MM-DD');
			} else {
				querystring.departdate = dayjs(values.travelDates[0]).format('YYYY-MM-DD');
				querystring.returndate = dayjs(values.travelDates[1]).format('YYYY-MM-DD');
			}

			const urlQuerystring = Object.keys(querystring).reduce((fullQuery, param) => {
				fullQuery.push(`${param}=${encodeURI(querystring[param])}`);
				return fullQuery;
			}, []);

			history.push(`/success/flights?${urlQuerystring.join('&')}`);
		} catch (error) {
			console.error(error);
		}
	};

	// set correct initial values
	useEffect(() => {
		if (state.question.path !== bookByDestination) {
			dispatch({
				type: SET_QUESTION_INFO,
				payload: { question: bookByDestination, heading: 'Book By Destination', answers: initialValues },
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// redirect to first question
	useEffect(() => {
		if (location.pathname !== `${match.url}/${routes[0].location}` && isEmpty(state.question.answers)) {
			history.push(`${match.url}/${routes[0].location}`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// redirect parent page root to step 1
	if (location.pathname === `${match.url}`) {
		return <Redirect exact from={`${match.url}`} to={`${match.url}/${routes[0].location}`} />;
	}

	return (
		<Formik
			initialValues={{ ...initialValues, ...state.question.answers }}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
		>
			{({ isSubmitting }) => (
				<Form className="travel-form">
					<AnimatePresence exitBeforeEnter>
						<Switch location={location} key={location.pathname}>
							{routes.map((route, i) => {
								const routeProps = {};

								if (routes[i + 1]) routeProps.next = `${match.url}/${routes[i + 1].location}`;
								if (routes[i - 1]) routeProps.prev = `${match.url}/${routes[i - 1].location}`;
								if (i === 0) routeProps.skipImage = true;
								if (routes.length === i + 1) routeProps.isSubmit = true;

								return (
									<Route key={route.location} path={`${match.url}/${route.location}`}>
										<route.component {...routeProps} />
									</Route>
								);
							})}
						</Switch>
					</AnimatePresence>
					{isSubmitting && (
						<div className="full-screen-spinner">
							<ProgressSpinner />
						</div>
					)}
				</Form>
			)}
		</Formik>
	);
};

BookByDestinationForm.displayName = 'BookByDestinationForm';

export default BookByDestinationForm;
