import { useContext } from 'react';
import { useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';
import { ResultsContext, SET_QUESTION_ANSWERS } from '../../context/ResultsContext';

const useNavigateNext = ({ field, noValidate = false, validate, url }) => {
	const { values } = useFormikContext();
	const history = useHistory();
	const { dispatch } = useContext(ResultsContext);

	const handleClick = async () => {
		if (noValidate) {
			dispatch({ type: SET_QUESTION_ANSWERS, payload: { answers: values } });
			return history.push(url);
		}

		const errors = await validate(true);

		if (!errors[field]) {
			dispatch({ type: SET_QUESTION_ANSWERS, payload: { answers: values } });
			return history.push(url);
		}

		console.log({ label: 'errors on question', errors: errors[field] });
		return false;
	};

	return handleClick;
};

useNavigateNext.displayName = 'useNavigateNext';

export default useNavigateNext;
