// control all filters
import { useContext, useEffect, useState } from 'react';
import { ResultsContext } from '../../../context/ResultsContext';
import './Filters.scss';
import MerchantFilters from './MerchantFilters';

const Filters = ({ updateResults }) => {
	const { state } = useContext(ResultsContext);
	const { results } = state.results.flights;
	const [activeFilters, setActiveFilters] = useState({});

	const filters = [MerchantFilters];

	// use active filters to update results
	useEffect(() => {
		const newResults = results.filter(result => {
			let status = false;
			const keys = Object.keys(activeFilters);

			for (let i = 0; i < keys.length; i++) {
				if (status) break;

				const filter = activeFilters[keys[i]];
				if (filter.run) {
					status = filter.run(result);
				}
			}

			return status;
		});

		console.log({ newResults });

		updateResults(newResults);
	}, [activeFilters, results, updateResults]);

	return (
		<div className="results-filters">
			<div className="results-filter-title">Filters</div>
			{filters.map((Filter, i) => (
				<Filter
					key={`filter_${i}`}
					results={results}
					activeFilters={activeFilters}
					setActiveFilters={setActiveFilters}
				/>
			))}
		</div>
	);
};

Filters.displayName = 'Filters';

export default Filters;
