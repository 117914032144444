import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'primereact/checkbox';
import { LocationContext } from '../../../context/LocationContext';

const MerchantFilters = ({ attr, results, activeFilters, setActiveFilters }) => {
	const { state } = useContext(LocationContext);
	const { merchants: allMerchants } = state;
	const [merchants, setMerchants] = useState([]);
	const { merchants: activeMerchants = { data: [] } } = activeFilters;

	useEffect(() => {
		const newMerchants = [];

		if (results) {
			results.forEach(result => {
				const airlineCode = result?.outbound?.flights[0]?.marketing_airline;
				const merchant = newMerchants.find(m => m.code === airlineCode);

				if (merchant) {
					merchant.count += 1;
				} else {
					const merchantLabel = allMerchants.find(m => m.code === airlineCode);
					const label = merchantLabel?.name || airlineCode;
					newMerchants.push({ code: airlineCode, label, count: 1 });
				}
			});
		}

		setMerchants(newMerchants);
	}, [results, allMerchants]);

	const merchantFilter = result => {
		const airline = result.outbound.flights[0].marketing_airline;
		return activeMerchants.data.length ? activeMerchants.data.indexOf(airline) > -1 : true;
	};

	const handleChange = e => {
		const { value } = e.target;
		const newActiveMerchants = { run: merchantFilter, data: [], ...activeMerchants };
		const index = newActiveMerchants.data.findIndex(merchant => merchant === value);

		if (index > -1) {
			newActiveMerchants.data.splice(index, 1);
		} else {
			newActiveMerchants.data.push(value);
		}

		setActiveFilters({ ...activeFilters, merchants: newActiveMerchants });
	};

	return (
		<div className="filter" key={`attr_${attr}`}>
			<p className="title">Airlines</p>
			{merchants.map(merchant => (
				<div className="filter-item" key={`merchant_${merchant.code}`}>
					<Checkbox
						inputId={`merchant_${merchant.code}`}
						value={merchant.code}
						onChange={handleChange}
						checked={activeMerchants.data.includes(merchant.code)}
					/>
					<label htmlFor={`merchant_${merchant.code}`}>
						<span className="filter-item-label">{merchant.label}</span>
						<span className="filter-item-count">({merchant.count})</span>
					</label>
				</div>
			))}
		</div>
	);
};

MerchantFilters.proptTypes = {
	attr: PropTypes.string.isRequired,
	results: PropTypes.object.isRequired,
	activeFilters: PropTypes.object.isRequired,
	setActiveFilters: PropTypes.func.isRequired,
};

MerchantFilters.displayName = 'MerchantFilters';

export default MerchantFilters;
