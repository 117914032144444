import PropTypes from 'prop-types';
import './buttonarrow.scss';

const ButtonArrowDisplay = ({ className = '', label, type = 'button', ...buttonProps }) => (
	<button className={`button-arrow ${className}`} type={type} {...buttonProps}>
		<span>{label}</span>
	</button>
);

ButtonArrowDisplay.displayName = 'ButtonArrow';
ButtonArrowDisplay.propTypes = {
	className: PropTypes.string,
	label: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	type: PropTypes.string,
};

export default ButtonArrowDisplay;
