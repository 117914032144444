import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { LocationContext, SET_CURRENT_LOCATION } from '../../context/LocationContext';
import PrevArrowButton from './PrevArrowButton';
import SubmitArrowButton from './SubmitArrowButton';
import NextArrowButton from './NextArrowButton';

const pageVariants = {
	initial: { opacity: 0, y: '200%' },
	in: { opacity: 1, y: '0' },
	out: { opacity: 0, y: '-200%' },
};

const pageTransitions = {
	type: 'tween',
	ease: 'anticipate',
	duration: 0.5,
};

const QuestionWrapper = ({ children, prev = '', field = '', skipImage = false, isSubmit = false, validate = null }) => {
	const { pathname } = useLocation();
	const { state, dispatch } = useContext(LocationContext);
	const { locations } = state;

	useEffect(() => {
		if (locations?.length > 0 && !skipImage) {
			dispatch({ type: SET_CURRENT_LOCATION, payload: pathname });
		}
	}, [pathname, dispatch, locations, skipImage]);

	return (
		<div className={`question-wrapper ${field}`}>
			<motion.div initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransitions}>
				{children}
			</motion.div>
			{prev && <PrevArrowButton url={prev} />}
			{isSubmit ? <SubmitArrowButton /> : <NextArrowButton handleClick={validate} />}
		</div>
	);
};

QuestionWrapper.propTypes = {
	children: PropTypes.element.isRequired,
	submit: PropTypes.bool,
	skipImage: PropTypes.bool,
	field: PropTypes.string.isRequired,
	validate: PropTypes.func,
};

export default QuestionWrapper;
