import { createContext, useReducer } from 'react';
import setCurrentLocation from './LocationContextFuncs';

export const LocationContext = createContext();
export const SET_INITAL_APP_DATA = 'SET_INITAL_APP_DATA';
export const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION';
export const SET_USERS_LOCATION = 'SET_USERS_LOCATION';

const initialState = {
	airports: [],
	currentPage: '',
	locations: [],
	merchants: [],
	remainingLocations: [],
	viewLocations: {},
	userLocation: {
		latitude: null,
		longitude: null,
	},
};

const reducer = (state, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_INITAL_APP_DATA:
			return {
				...state,
				locations: payload.locations,
				remainingLocations: payload.locations,
				airports: payload.airports,
				merchants: payload.merchants,
			};
		case SET_CURRENT_LOCATION:
			return setCurrentLocation(state, payload);
		case SET_USERS_LOCATION:
			return { ...state, userLocation: payload };
		default:
			return state;
	}
};

const LocationContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	return <LocationContext.Provider value={{ state, dispatch }}>{children}</LocationContext.Provider>;
};

export default LocationContextProvider;
