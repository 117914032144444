import { createContext, useReducer } from 'react';

export const ResultsContext = createContext();

export const SET_FLIGHT_RESULTS = 'SET_FLIGHT_RESULTS';
export const SET_QUESTION_INFO = 'SET_QUESTION_INFO';
export const SET_QUESTION_ANSWERS = 'SET_QUESTION_ANSWERS';
export const RESET_QUESTION_ANSWERS = 'RESET_QUESTION_ANSWERS';

const initialState = {
	results: { flights: {} },
	question: {
		path: '',
		heading: '',
		answers: {},
	},
};

const reducer = (state, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_FLIGHT_RESULTS:
			return { ...state, results: { flights: payload } };
		case SET_QUESTION_INFO:
			return { ...state, question: { answers: payload.answers, path: payload.question, heading: payload.heading } };
		case SET_QUESTION_ANSWERS:
			return { ...state, question: { ...state.question, answers: payload.answers } };
		case RESET_QUESTION_ANSWERS:
			return { ...state, question: { ...initialState.question } };
		default:
			return state;
	}
};

const ResultsContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	return <ResultsContext.Provider value={{ state, dispatch }}>{children}</ResultsContext.Provider>;
};

export default ResultsContextProvider;
