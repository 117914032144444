const destinationItemDisplay = item => (
	<div className="destination-item">
		<span>
			{item.address.localName || item.address.freeformAddress}, {item.address.countryCodeISO3}
		</span>
	</div>
);

const airportItemDisplay = item => (
	<div className="airport-item">
		<div className="airport-item-title">{item.poi.name}</div>
		<div className="airport-item-info">
			{item.address.municipality}, {item.address.countrySubdivision}, {item.address.countryCodeISO3}
		</div>
	</div>
);

export { destinationItemDisplay, airportItemDisplay };
