import { useState } from 'react';
import DestinationPage from './templates';
import { urlkeys } from '../utils/constants';

const { bookByBudget } = urlkeys;

const BookByBudgetPage = () => {
	const [budget, setBudget] = useState('');

	return (
		<DestinationPage className="book-by-budget-page" urlkey={bookByBudget}>
			<div className="page-wrapper">
				<div className="page-content">
					<div className="page-heading">Book By Budget</div>
					<div className="page-question">
						<span>I want to spend about</span>
						<input type="text" name="budget" value={budget} onChange={ev => setBudget(ev.target.value)} />
						<span>on this trip.</span>
					</div>
				</div>
			</div>
		</DestinationPage>
	);
};

BookByBudgetPage.displayName = 'BookByBudgetPage';

export default BookByBudgetPage;
