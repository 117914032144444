import { useRef } from 'react';
import PropTypes from 'prop-types';
import './PassengerNumberQuestion.scss';
import { useField } from 'formik';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { InputText } from 'primereact/inputtext';
import QuestionWrapper from '../global/QuestionWrapper';
import useNavigateNext from '../hooks/useNavigateNext';

const buttonClasses = 'p-button-rounded p-button-outlined p-button-sm';

const passengerInfo = {
	adults: {
		label: 'Adults',
		subtext: '(12+ years old)',
	},
	children: {
		label: 'Children',
		subtext: '(2 - 12 years old)',
	},
	infants: {
		label: 'Infants',
		subtext: '(under 2 years old)',
	},
};

const NumberIncrementMenu = ({ field, meta, form }) => {
	const op = useRef(null);
	const { onBlur, value, ...fieldProps } = field;

	const showError = meta.touched && meta.error && meta.error.total;

	const incValue = state => {
		form.setValue({ ...field.value, [state]: field.value[state] + 1, total: field.value.total + 1 });
	};

	const decValue = state => {
		if (field.value[state] > 0) {
			form.setValue({ ...field.value, [state]: field.value[state] - 1, total: field.value.total - 1 });
		}
	};

	return (
		<div className="inline-number-input">
			<InputText
				readOnly
				type="text"
				onClick={e => op.current.toggle(e)}
				value={value.total}
				className={showError ? 'p-invalid' : ''}
				{...fieldProps}
			/>
			<OverlayPanel
				ref={op}
				showCloseIcon
				id="overlay_panel"
				className="number-input-overlay"
				appendTo={document.querySelector('#root')}
			>
				{Object.keys(field.value).map(
					option =>
						option !== 'total' && (
							<div key={option} className="passenger option">
								<div className="option-title">
									<span>{passengerInfo[option].label}</span>
									<span className="subtext">{passengerInfo[option].subtext}</span>
								</div>
								<div className="option-settings">
									<Button icon="pi pi-minus" onClick={() => decValue(option)} className={buttonClasses} />
									<span className="number-output">{field.value[option]}</span>
									<Button icon="pi pi-plus" onClick={() => incValue(option)} className={buttonClasses} />
								</div>
							</div>
						)
				)}
			</OverlayPanel>
			{showError && <small className="p-invalid">{meta.error.total}</small>}
		</div>
	);
};

function PassengersNumberQuestion({ next, ...rest }) {
	const [field, meta, form] = useField('passengers');
	const navigateNext = useNavigateNext({ url: next, validate: form.setTouched, field: field.name });

	return (
		<QuestionWrapper field={field.name} validate={navigateNext} {...rest}>
			<div className="page-question">
				<span>There {field.value.total > 1 ? 'are' : 'is'}</span>
				<div className="field-wrapper">
					<NumberIncrementMenu field={field} meta={meta} form={form} />
				</div>
				<span>of us travelling</span>
			</div>
		</QuestionWrapper>
	);
}

PassengersNumberQuestion.displayName = 'PassengersNumberQuestion';

PassengersNumberQuestion.propTypes = {
	next: PropTypes.string,
	submit: PropTypes.bool,
};

PassengersNumberQuestion.defaultProps = {
	next: '',
	submit: false,
};

export default PassengersNumberQuestion;
