import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import './index.scss';
import './themes/md-light-blue/theme.scss';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import App from './App';
import * as serviceWorker from './serviceWorker';
import LocationContextProvider from './context/LocationContext';
import ResultsContextProvider from './context/ResultsContext';

ReactDOM.render(
	<StrictMode>
		<LocationContextProvider>
			<ResultsContextProvider>
				<Router>
					<App />
				</Router>
			</ResultsContextProvider>
		</LocationContextProvider>
	</StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
